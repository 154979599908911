/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'assets/fonts/inter/inter.css';
@import 'assets/styles/splash-screen.css';

.mat-step-label {
    display: none !important;
}
.custome-snack-bar.mat-mdc-snack-bar-container
.mdc-snackbar__surface {
    background-color: red!important;
    color: white !important;
}
.text-right {
    text-align: right !important;
}
.text-start{
    text-align: start;
}